import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Closing the Sale`}</h1>
    <p>{`Closing will occur as per the terms of the Purchase Agreement that is located in the ‘document’ section of the parcel. Your bid encompasses your monetary bid amount plus the terms as set out in the designated Purchase Agreement. In the event a potential bidder has any questions about the terms, they are encouraged to seek input from their independent legal advisors. Bidding means you are in agreement with the designated Purchase Agreement for that parcel.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      